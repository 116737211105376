import React, { Fragment } from "react";
import { IoLogoYoutube, IoLogoFacebook, IoLogoInstagram } from "react-icons/io5";
import { Link } from 'react-router-dom';
import Logo from "../../components/logo/Logo";
import config from "../../utils/config";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { settingsData } from "../../store/reducers/settingsSlice";

const Footer = ({ t }) => {

    const selectdata = useSelector(settingsData);

    const appdata = selectdata && selectdata.filter(item => item.type == "app_link");

    const appLink = appdata && appdata.length > 0 ? appdata[0].message : '';

    const appiosdata = selectdata && selectdata.filter(item => item.type == "ios_app_link");

    const appiosLink = appiosdata && appiosdata.length > 0 ? appiosdata[0].message : '';


    //social media data
    const socialdata = [
        {
            id: 1,
            sodata: <IoLogoFacebook />,
            link: config.facebooklink,
        },
        {
            id: 2,
            sodata: <IoLogoInstagram />,
            link: config.instagramlink,
        },
        {
            id: 3,
            sodata: <IoLogoYoutube />,
            link: config.youtubelink,
        },
    ];

    return (
        <Fragment>
            <div className="footer_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3  col-12 footer_left">
                            <div className="footer_logo">
                                <Logo image={process.env.PUBLIC_URL + `/images/logo/Quiz.png`} />
                            </div>
                            <div className="footer_left_text">
                                <p>{t(`${config.companytext}`)}</p>
                            </div>
                            <div className="two_images d-flex align-item-center flex-wrap">
                                <div className="playstore_img me-1">
                                    <Link to={appLink} target="_blank">
                                        <img src={process.env.PUBLIC_URL + `/images/footer/playstore.svg`} alt="playstore" />
                                    </Link>
                                </div>
                                <div className="playstore_img iosimg">
                                    <Link to={appiosLink} target="_blank">
                                        <img src={process.env.PUBLIC_URL + `/images/footer/appstore.svg`} alt="ios" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3  col-12 footer_left_second">
                            <div className="footer_title">
                                <h4 className="footer_heading">{t("Policy")}</h4>
                            </div>
                            <ul className="footer_policy">
                                <li className="footer_list">
                                    <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
                                </li>
                                <li className="footer_list">
                                    <Link to="/terms-conditions">{t("Terms and Conditions")}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3  col-12 footer_right">
                            <div className="footer_title">
                                <h4 className="footer_heading">{t("Company")}</h4>
                            </div>
                            <ul className="footer_policy">
                                <li className="footer_list">
                                    <Link to="/about-us">{t("About Us")}</Link>
                                </li>
                                <li className="footer_list">
                                    <Link to="/contact-us">{t("Contact Us")}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12 footer_right">
                            <div className="footer_title">
                                <h4 className="footer_heading">{t("Find Us Here")}</h4>
                            </div>
                            <ul className="footer_policy">
                                {/* <li className="footer_list_address">{t(`${config.addresstext}`)}</li> */}
                                <li className="footer_list_email">
                                    <a href={`mailto:${config.email}`}>{t(`${config.email}`)}</a>
                                </li>
                                <li className="footer_list_number">
                                    <a href={`tel:${config.phonenumber}`}>{t(`${config.phonenumber}`)}</a>
                                </li>
                            </ul>
                            <ul className="footer_social">
                                {socialdata.map((data) => (
                                    <li className="footer_social_list" key={data.id}>
                                        <a href={data.link}>
                                            <i>{data.sodata}</i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <hr />

                    <div className="footer_copyright text-center">
                        <p>
                            {t("Copyright")} © {new Date().getFullYear()} {t("Made By")}{" "}
                            <a href={`${config.weblink}`} target="_blank">
                                {t(`${config.companyname}`)}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withTranslation()(Footer);
