let config = {
  // Set the Demo Version
  demo: false,

  // you get this type data from google adsense

  // <ins class="adsbygoogle"
  //    style="display:block"
  //    data-ad-client="xxxxxxxxxxxx"
  //    data-ad-slot="xxxxxxxxxxxxxx"
  //    data-ad-format="auto"
  //    data-full-width-responsive="true"></ins>

  // googleAddsense
  data_ad_client: "ca-pub-1744104223400387", //ca-pub-your-ad-client-id
  // data_ad_slot:"9154149295", //your-ad-slot-id

  //SEO Configurations
  metaDescription: "Ace Test and Boost Your Canadian Knowledge!",
  metaKeyWords: "TestYourKnowledge ,DiscoverCanada ,CanadaQuizApp ,CanadaQuiz ,canada_facts ,canada_quiz",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds


  // audio question timer
  audiotimer: 30,
  mathtimer:30,

  //Firebase Configurations

  apiKey: "AIzaSyDGJHo1YZeX6OUWINrLx0D3ZeoJCCj_zqc",
  authDomain: "canada-quiz-d96cf.firebaseapp.com",
  projectId: "canada-quiz-d96cf",
  storageBucket: "canada-quiz-d96cf.appspot.com",
  messagingSenderId: "1008535155381",
  appId: "1:1008535155381:web:3e728e837a5a130903c4fc",
  measurementId: "G-2J3N6MFZTH",

  //footer area
  companytext: "Ace Test and Boost Your Canadian Knowledge!",
  addresstext: "",
  phonenumber: "+1 226239483",
  email: "support@canada-quiz.ca",
  facebooklink: "https://www.facebook.com/canada.quiz.appl",
  instagramlink: "https://www.instagram.com/canada.quiz",
  youtubelink: "https://www.youtube.com/@Share-tube",
  companyname: "Canada Quiz.",
};

export default config;
